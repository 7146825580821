import axios from "axios";
import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  loading: true,
  errorMsg: "",
  files: [],
  searchFiles: [],
  sasToken: "",
  selected: []
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_FILE_LIST_BEGIN = "FETCH_FILE_LIST_BEGIN";
const FETCH_FILE_LIST_SUCCESS = "FETCH_FILE_LIST_SUCCESS";
const FETCH_FILE_LIST_FAILURE = "FETCH_FILE_LIST_FAILURE";
const SET_SEARCH_FILES = "SET_SEARCH_FILES";
//const SET_REFRESH_FILES = "SET_REFRESH_FILES";
const SET_SELECTED_FILES = "SET_SELECTED_FILES";
const RESET_SELECTED_FILES = "RESET_SELECTED_FILES";
//const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";

/* -------------------- ACTION CREATORS -------------------- */

const fetchFileListBegin = () => ({
  type: FETCH_FILE_LIST_BEGIN
});
export const fetchFileListSuccess = files => {
  return { type: FETCH_FILE_LIST_SUCCESS, payload: files };
};
const setSearchFiles = files => {
  return { type: SET_SEARCH_FILES, payload: files };
};

const fetchFileListError = error => ({
  type: FETCH_FILE_LIST_FAILURE,
  payload: error
});

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getFileList = client => {
  return dispatch => {
    dispatch(fetchFileListBegin());

    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/listFilesinContainer`,
        {
          client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchFileListSuccess(response.data.response));
      })
      .catch(error => {
        dispatch(fetchFileListError("No Files"));
      });
  };
};

export const filterFiles = (filter, files) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchFiles(files));
    } else {
      let newFiles = cloneDeep(files);
      let nFiles = [];

      newFiles.forEach((newFile, newFileIdx) => {
        if (newFile.children === undefined) {
          if (newFile.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
            return nFiles.push(newFile);
        } else {
          newFile.children = newFile.children.filter((file, index) => {
            if (file.children === undefined) {
              return (
                file.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
              );
            } else {
              return true;
            }
          });
          newFile.children.forEach((file, index) => {
            if (file.children !== undefined) {
              let tempFiles = cloneDeep(file);
              file.children = [];
              tempFiles.children.forEach((cFile, cIndex) => {
                if (
                  cFile.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                ) {
                  newFile.children[index].children.push(cFile);
                }
              });
            }
          });
          nFiles.push(newFile);
        }
      });
      dispatch(setSearchFiles(nFiles));
    }
  };
};
export const selectFiles = (files, select) => {
  return { type: SET_SELECTED_FILES, files, select };
};
export const resetFiles = () => {
  return { type: RESET_SELECTED_FILES };
};
export const setRefresh = (client, files, refresh) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/setRefresh`,
        {
          client: client,
          files,
          refresh
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getFileList(client));
      })
      .catch(error => {
        dispatch(fetchFileListError(error));
      });
  };
};

// export const deleteReport = (groupId, reportId) => {
//   return dispatch => {
//     axios
//       .post(
//         `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/deleteReport`,
//         {
//           groupId,
//           reportId
//         },
//         {
//           headers: {
//             Authorization: "Bearer " + localStorage.getItem("access_token")
//           }
//         }
//       )
//       .then(response => {
//         dispatch(deleteReportSuccess(response.data.response));
//       });
//   };
// };

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FILE_LIST_BEGIN:
      return { ...state, loading: true, error: null };
    case FETCH_FILE_LIST_SUCCESS:
      return {
        ...state,
        files: action.payload,
        searchFiles: action.payload,
        loading: false,
        errorMsg: ""
      };
    case FETCH_FILE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.payload,
        files: [],
        searchFiles: []
      };
    case SET_SEARCH_FILES:
      return {
        ...state,
        searchFiles: action.payload
      };
    case RESET_SELECTED_FILES:
      return { ...state, selected: [], files: [] };
    case SET_SELECTED_FILES:
      let newSelected = cloneDeep(state.selected);
      let newFiles = cloneDeep(state.files);
      newFiles = newFiles.map(file => {
        if (file.name === action.files.name) {
          file.selected = action.select;
        }
        return file;
      });
      action.files.selected = action.select;
      if (action.select) {
        newSelected.push(action.files);
      } else {
        if (Array.isArray(action.files)) {
          let aFiles = action.files.map(file => {
            return file.path;
          });
          newSelected = newSelected.filter(file => {
            return aFiles.indexOf(file.path) === -1;
          });
        } else {
          newSelected = newSelected.filter(file => {
            return file.path !== action.files.path;
          });
        }
      }
      return {
        ...state,
        selected: newSelected,
        files: newFiles
      };
    default:
      return state;
  }
};
