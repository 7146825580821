import React, { Component } from "react";
import Select from "react-select";

import { connect } from "react-redux";
import {
  getClients,
  setClient,
  addNewClient,
  getAvailableApps,
  saveClient,
  deleteClient,
  runExcelRefresh,
  setDefaultApp,
  loadAppPerms,
  setAppPerms,
  setDefaultAppList,
  getSelectedHPRApps,
  setSelectedHPRApps,
  setSelectedDashboardReports,
  getSelectedDashboardReports,
  setHPRUpdateStatus
} from "../../reducers/clientReducer";
import {
  getActiveWorkspaceForClient,
  filterActiveWorkspaces,
  filterActiveWorkspacesWReports
} from "../../reducers/workspaceReducer";
import {
  getGroups,
  filterGroups,
  addBlankGroup
} from "../../reducers/groupReducer";
import validator from "validator";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getCustomApps,
  filterApps
} from "../../reducers/customApplicationReducer";
import { LeftArrow } from "../../lib/icons";
import { DashboardRptItem } from "../dashboardRptItem";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "4px",
  margin: `0 0 ${grid}px 0`,
  height: "30px",
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
  border: "1px solid #dbddde",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#f7f8fa",
  padding: grid
  // width: 250,
});
class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: null,
      showAddClientModal: false,
      reportListType: "",
      listTypeLoaded: false,
      apps: [],
      unsavedChanges: false,
      showIsDirtyModal: false,
      showGroupMappingModal: false,
      showDeleteConfirm: false,
      authType: "",
      embedType: "",
      domain: [],
      mdmClientId: "",
      hardDelete: false,
      removeFromAd: true,
      selectedApp: null,
      appsOrder: [],
      hprAppsOrder: [],
      dashboardReportsOrder: [],
      reportUrl: "",
      enableReportUrl: false,
      inputValue: "",
      stateGroups: [],
      ssoConfigEnabled: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.addClient = this.addClient.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleExcelRefresh = this.handleExcelRefresh.bind(this);
    this.selectGroups = this.selectGroups.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.renderThumbs = this.renderThumbs.bind(this);
    this.reportUrl = React.createRef();
    this.stateGroupChangeHandler = this.stateGroupChangeHandler.bind(this);
    this.saveMappingHandler = this.saveMappingHandler.bind(this);
    this.addHomePageRptApps = this.addHomePageRptApps.bind(this);
    this.addDashboardReports = this.addDashboardReports.bind(this);
  }
  componentDidUpdate() {
    // console.log("component did update " + this.state.listTypeLoaded);
    if (
      this.props.loadedApps &&
      !this.state.listTypeLoaded &&
      !this.props.activeLoading
    ) {
      let selectedAvApps = cloneDeep(this.props.availableApps);
      let showActiveWorkspaces = false;
      selectedAvApps = selectedAvApps.filter(item => {
        if (
          item.checked &&
          (item.type === "powerBI" || item.type === "tableau")
        ) {
          showActiveWorkspaces = true;
        }
        return item.checked;
      });

      let appsOrder = [...selectedAvApps];
      let hprAppsOrder = this.props.selectedHPRApps;
      let dashboardRptsOrder = this.props.dashboardReportsOrder;

      // console.log("CUSTOM APPS");
      // console.log(this.props.customApps);
      // console.log("ActiveWorkspaces");
      // console.log(this.props.activeWorkspaces);
      if (showActiveWorkspaces) {
        appsOrder = [
          ...selectedAvApps,
          ...this.props.customApps,
          ...this.props.activeWorkspaces
        ];
      }
      var c = [];
      var addArr = [];
      var defaultApps = [];

      let clientAppsOrder = this.props.selectedClient.appsOrder;
      let clientHprAppsOrder = this.props.selectedClient.hprAppsOrder;

      let clientDashboardRptsOrder =
        this.props.selectedClient.dashboardReportsOrder;
      let activeDashboardReports = [];
      this.props.selectedDashboardReports.forEach(item => {
        item.reports.forEach(rpt => {
          activeDashboardReports.push(rpt);
        });
      });

      appsOrder.forEach(item => {
        if (!clientAppsOrder.includes(item._id)) {
          clientAppsOrder.push(item._id);
        }
      });
      hprAppsOrder.forEach(item => {
        if (item._id !== undefined && !clientHprAppsOrder.includes(item._id)) {
          if (item._id !== undefined) clientHprAppsOrder.push(item._id);
        } else if (!clientHprAppsOrder.includes(item)) {
          clientHprAppsOrder.push(item);
        }
      });

      dashboardRptsOrder.forEach(item => {
        if (
          item._id !== undefined &&
          !clientDashboardRptsOrder.includes(item._id)
        ) {
          if (item._id !== undefined) clientDashboardRptsOrder.push(item._id);
        } else if (!clientDashboardRptsOrder.includes(item)) {
          clientDashboardRptsOrder.push(item);
        }
      });

      if (this.props.selectedClient.appsOrder !== undefined) {
        this.props.selectedClient.appsOrder.forEach(el => {
          let found = appsOrder.find(e => {
            if (e.title === "TABS Insight" || e.title === "Tableau") {
              return false;
            }
            return e._id === el;
          });

          if (found !== undefined) {
            c.push(found);

            if (found.title !== undefined) {
              found.label = found.title;
              found.name = found.title;
              found.value = found.title;
            } else if (found.name !== undefined) {
              if (
                found.alias !== undefined &&
                found.alias !== null &&
                found.alias !== "" &&
                found.alias !== found.title
              ) {
                let sTitle = found.name + " (Alias: " + found.alias + ")";
                found.label = sTitle;
                found.name = sTitle;
                found.value = sTitle;
              } else {
                found.label = found.name;
                found.value = found.name;
              }

              if (found.type === undefined) {
                found.link = "/reports/landing/" + found._id;
              } else if (found.type === "tableau") {
                found.link = "/tableau/report/" + found.workspaceId;
              } else if (found.type === "powerBI") {
                //found.link = "/powerBI/report/"+
              }
            }
            defaultApps.push(found);
          } else {
            addArr.push(el);
          }
        });
        c = c.filter(item => {
          return item !== undefined;
        });
        // appsOrder = sortBy(appsOrder, [
        //   function (o) {
        //     return o.appOrder;
        //   }
        // ]);
        appsOrder = c;
      } else {
        appsOrder = [];
      }
      defaultApps.push({
        label: "Reporting",
        value: "Reporting",
        link: "/reports/landing",
        name: "Reporting"
      });
      defaultApps.push({
        label: "Specified Report",
        value: "Specified Report"
      });
      defaultApps = defaultApps.filter(item => {
        if (item.label === "Tableau") {
          return false;
        } else if (item.label === "TABS Insight") {
          return false;
        } else {
          return true;
        }
      });

      let enableReportUrl = false;
      if (this.props.defaultApp.label === "Specified Report") {
        this.reportUrl.current.value = this.props.defaultApp.link;
        enableReportUrl = true;
      }
      let tmpHprApps = [];
      let tmpDashboardRpts = [];
      clientHprAppsOrder.forEach(item => {
        [
          ...this.props.searchCustomApps,
          ...this.props.searchActiveWorkspaces
        ].forEach(sItem => {
          if (sItem._id === item) {
            tmpHprApps.push(sItem);
          }
        });
      });
      clientDashboardRptsOrder.forEach(item => {
        activeDashboardReports.forEach(sItem => {
          if (sItem._id === item) {
            tmpDashboardRpts.push(sItem);
          }
        });
      });
      // console.log("HPR APPS ORDER");
      // console.log(tmpHprApps);
      tmpHprApps = tmpHprApps.filter(item => {
        // console.log("ITEM _ID " + item._id);
        // console.log(this.props.selectedHPRApps.includes(item._id.toString()));
        return this.props.selectedHPRApps.includes(item._id.toString());
      });
      // this.props.setSelectedDashboardReports(
      //   tmpDashboardRpts,
      //   dashboardRptsOrder
      // );
      this.setState({
        reportListType: this.props.reportListType,
        listTypeLoaded: true,
        authType: this.props.authType,
        embedType: this.props.embedType,
        apps: this.props.availableApps,
        domain: this.props.domain,
        mdmClientId: this.props.mdmClientId,
        appsOrder,
        hprAppsOrder: tmpHprApps,
        dashboardReportsOrder: tmpDashboardRpts,
        defaultApps,
        enableReportUrl,
        ssoConfigEnabled: this.props.authType === "saml"
      });
      this.clientId.value = this.props.mdmClientId;
    } else if (
      this.props.selectedHPRApps.length > 0 &&
      this.props.runUpdateHPRApps
    ) {
      // console.log("IN THE ELFE IF ");
      // console.log(this.props.selectedHPRApps);
      let hprAppsOrder = this.props.selectedHPRApps;
      let clientHprAppsOrder = this.props.selectedClient.hprAppsOrder;
      hprAppsOrder.forEach(item => {
        if (item._id !== undefined && !clientHprAppsOrder.includes(item._id)) {
          if (item._id !== undefined) clientHprAppsOrder.push(item._id);
        } else if (!clientHprAppsOrder.includes(item)) {
          clientHprAppsOrder.push(item);
        }
      });
      let tmpHprApps = [];
      clientHprAppsOrder.forEach(item => {
        [
          ...this.props.searchCustomApps,
          ...this.props.searchActiveWorkspaces
        ].forEach(sItem => {
          if (sItem._id === item) {
            tmpHprApps.push(sItem);
          }
        });
      });
      // console.log("TMP HPR APPS");
      // console.log(tmpHprApps);
      // console.log("SELECTED HPR");
      // console.log(this.props.selectedHPRApps);
      tmpHprApps = tmpHprApps.filter(item => {
        // console.log("ITEM _ID " + item._id);
        // console.log(this.props.selectedHPRApps.includes(item._id.toString()));
        return this.props.selectedHPRApps.includes(item._id.toString());
      });
      // console.log("TMP HPR APPS AFTER");
      // console.log(tmpHprApps);
      this.setState({ hprAppsOrder: tmpHprApps });
      this.props.setHPRUpdateStatus();
    }
  }
  async checkboxChangeHandler(event, app, type) {
    if (type === "reportList") {
      this.setState({ reportListType: app, unsavedChanges: true });
    } else if (type === "authType") {
      this.setState({ authType: app, unsavedChanges: true });
      // console.log("app: ", app);
      if (app === "saml") {
        this.setState({ ssoConfigEnabled: true });
      } else {
        this.setState({ ssoConfigEnabled: false });
      }
    } else if (type === "embedType") {
      this.setState({ embedType: app, unsavedChanges: true });
    } else if (type === "clientDelete") {
      if (app === "removeAD") {
        this.setState({ removeFromAd: event.currentTarget.checked });
      } else if (app === "hardDelete") {
        this.setState({ hardDelete: event.currentTarget.checked });
      }
    } else if (type === "permissionSelect") {
      this.props.setAppPerms(app, undefined);
      this.setState({ unsavedChanges: true });
    } else if (type === "dashboardReports") {
      let newDR = cloneDeep(this.props.selectedDashboardReports);
      let rptIds = [];

      this.props.selectedDashboardReports.forEach(ws => {
        ws.reports.forEach(rpt => {
          if (rpt._id) {
            rptIds.push(rpt.workspace + "+" + rpt._id);
          } else {
            rptIds.push(rpt.workspace + "+" + rpt.id);
          }
        });
      });
      let reportId;
      if (app._id) {
        reportId = app.workspace + "+" + app._id;
      } else {
        reportId = app.workspace + "+" + app.id;
      }

      if (rptIds.includes(reportId)) {
        // If it exists, remove the report
        newDR = newDR
          .map(ws => {
            if (
              typeof ws.workspaceId === "object" &&
              ws.workspaceId !== null &&
              ws.workspaceId._id === app.workspace
            ) {
              return {
                ...ws,
                reports: ws.reports.filter(rpt => rpt._id !== app._id)
              };
            } else if (ws.workspaceId === app.workspaceId) {
              return {
                ...ws,
                reports: ws.reports.filter(rpt => rpt._id !== app._id)
              };
            }

            return ws;
          })
          .filter(ws => ws.reports.length > 0);
      } else {
        // If it doesn't exist, add the report to the workspace
        const existingWorkspace = newDR.find(ws => {
          if (typeof ws.workspaceId === "object" && ws.workspaceId !== null) {
            if (app.workspace.includes("-")) {
              return ws.workspaceId.workspaceId === app.workspace;
            } else {
              return ws.workspaceId._id === app.workspace;
            }
          } else {
            return ws.workspaceId === app.workspace;
          }
        });
        if (existingWorkspace) {
          // Add the report to the existing workspace
          existingWorkspace.reports.push({
            _id: app._id || app.id,
            name: app.name,
            workspace: app.workspace,
            workspaceId: app.workspace
          });
        } else {
          // Create a new workspace with the report
          newDR.push({
            _id: app.workspace,
            workspaceId: app.workspace,
            reports: [
              {
                _id: app._id || app.id,
                name: app.name,
                workspace: app.workspace
              }
            ]
          });
        }
      }

      // const workspaceId = app.workspace;
      // // const reportId = app._id;
      // // Find the workspace in selectedDashboardReports
      // const existingWorkspace = updatedReports.find(
      //   ws => ws.workspace === workspaceId
      // );
      // if (existingWorkspace) {
      //   // Check if the report is already selected
      //   const reportIndex = existingWorkspace.reports.findIndex(
      //     rpt => rpt._id === reportId
      //   );

      //   if (reportIndex !== -1) {
      //     // If report exists, remove it
      //     existingWorkspace.reports.splice(reportIndex, 1);

      //     // If no reports left in this workspace, remove the workspace
      //     if (existingWorkspace.reports.length === 0) {
      //       updatedReports = updatedReports.filter(
      //         ws => ws.workspace !== workspaceId
      //       );
      //     }
      //   } else {
      //     // If report does not exist, add it
      //     existingWorkspace.reports.push({ _id: reportId, name: app.name });
      //   }
      // } else {
      //   // If the workspace doesn't exist, create a new workspace entry
      //   updatedReports.push({
      //     _id: workspaceId,
      //     reports: [{ _id: reportId, name: app.name }]
      //   });
      // }
      // newDR = updatedReports;

      // Now update the state or trigger the corresponding update

      this.props.setSelectedDashboardReports(newDR, [
        ...this.props.dashboardReportsOrder,
        app._id
      ]);
    } else if (type === "hprApps") {
      //check to see if the app is already in the array
      let newHPRApps = cloneDeep(this.props.selectedHPRApps);
      if (this.props.selectedHPRApps.includes(app._id)) {
        //remove the app
        newHPRApps = newHPRApps.filter(item => {
          if (item.id === undefined) {
            return item !== app._id;
          }
          return item.id !== app._id;
        });
      } else {
        newHPRApps.push(app._id);
      }
      this.props.setSelectedHPRApps(newHPRApps);
      // this.setState({ selectedHPRApps: newHPRApps });
    } else {
      let newApps = cloneDeep(this.state.apps);
      let addItem = false;
      let showActiveWorkspaces = false;
      newApps = newApps.map(newApp => {
        if (app._id === newApp._id && type !== "fileChange") {
          newApp.checked = !app.checked;
          addItem = newApp.checked;
        }
        if (
          newApp.checked &&
          (newApp.type === "powerBI" || newApp.type === "tableau")
        ) {
          showActiveWorkspaces = true;
        }
        if (
          newApp.checked &&
          newApp.type === "files" &&
          type === "fileChange"
        ) {
          newApp.useFileFolderPermissions = !app.useFileFolderPermissions;
        }
        return newApp;
      });

      let appListObjs = newApps.filter(item => {
        // if (item.title === "Tableau" || item.title === "TABS Insight") {
        //   return false;
        // }
        return item.checked;
      });

      let appList = appListObjs.map(item => {
        return item.type.toLowerCase();
      });
      let newAppList = cloneDeep(this.props.defaultAppListItems);

      if (addItem) {
        if (app.type === "mdh") {
          await axios
            .post(
              `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getMdmApps`,
              {
                client: this.props.selectedClient.value
                //idToken: localStorage.getItem("id_token")
              },
              {
                headers: {
                  //Authorization: "Bearer " + localStorage.getItem("access_token")
                  ClientToken: localStorage.getItem("clientToken")
                }
              }
            )
            .then(response => {
              response.data.apps.forEach((app, index) => {
                let newIdx = index + 1;

                newAppList.push({
                  label: "MDH:" + app["APP_NAME"],
                  value: "MDH:" + app["APP_NAME"],
                  name: "MDH:" + app["APP_NAME"],
                  link: "/mdm/dataManagement/" + newIdx + "+0",
                  permissions: []
                });
              });
            });
        } else if (app.type === "tableau" || app.type === "powerBI") {
          let reportingExists = newAppList.filter(item => {
            return item.name === "Reporting";
          });
          if (reportingExists.length === 0) {
            newAppList.push({
              label: "Reporting",
              value: "Reporting",
              name: "Reporting",
              link: "/reports/landing",
              permissions: []
            });
          }
        } else {
          newAppList.push({
            label: app.title,
            value: app.title,
            name: app.title,
            link: app.link,
            permissions: []
          });
        }
        this.props.setDefaultAppList(newAppList);
      } else {
        newAppList = newAppList.filter(item => {
          if (appList.includes(item.name.toLowerCase())) {
            return true;
          } else if (item.name.includes(":")) {
            return appList.includes("mdh");
          } else if (item.name.includes("Promo")) {
            return appList.includes("promoopt");
          } else if (item.name.includes("Reporting")) {
            return appList.includes("tableau") || appList.includes("powerbi");
          } else {
            return false;
          }
        });

        let defaultExists = newAppList.filter(item => {
          return item.label === this.props.defaultApp.label;
        });

        if (defaultExists.length === 0) {
          this.props.setDefaultApp(newAppList[0]);
        }
        this.props.setDefaultAppList(newAppList);
      }
      let appsOrder = [...appListObjs];
      if (showActiveWorkspaces) {
        appsOrder = [
          ...appListObjs,
          ...this.props.customApps,
          ...this.props.activeWorkspaces
        ];
      }

      this.setState({ apps: newApps, unsavedChanges: true, appsOrder });
    }
  }
  cancelHandler() {
    this.setState({
      apps: this.props.availableApps,
      reportListType: this.props.reportListType
    });
    this.handleClose();
  }

  componentDidMount() {
    this.props.getClients();
    if (this.props.selectedClient !== null) {
      this.setState({
        selectedClient: {
          label: this.props.selectedClient.label,
          value: this.props.selectedClient.value
        }
      });

      this.props.getAvailableApps(this.props.selectedClient.value);
      this.props.getSelectedHPRApps(this.props.selectedClient.value);
      this.props.getSelectedDashboardReports(this.props.selectedClient.value);
      this.props.getGroups(this.props.selectedClient.value);
      this.props.getCustomApps(this.props.selectedClient.value);
      this.props.getActiveWorkspaceForClient(this.props.selectedClient.value);
    }
  }
  addClient() {
    let clientName = this.state.inputValue;
    if (clientName === "") {
      window.alert("You need to specify a Client Name");
    } else if (!validator.isLength(clientName, { min: 3, max: 63 })) {
      window.alert(
        "Client Name must be at least 3 characters and not more than 63"
      );
    } else {
      this.props.addNewClient(clientName);
      this.setState({ showAddClientModal: false, inputValue: "" });
      // eslint-disable-next-line quotes
      toast.success('Client "' + clientName + '" added.');
    }
  }
  saveHandler() {
    const {
      selectedClient,
      apps,
      reportListType,
      domain,
      authType,
      mdmClientId,
      appsOrder,
      hprAppsOrder,
      embedType
    } = this.state;
    const { defaultApp, defaultAppListItems } = this.props;

    let sDefaultApp = defaultApp;

    if (sDefaultApp.label === "Specified Report") {
      sDefaultApp.link = this.reportUrl.current.value;
    } else if (sDefaultApp.type === "tableau") {
      sDefaultApp.link = "/reports/landing/" + sDefaultApp.workspaceId;
    }
    let orderArr = appsOrder.map((item, idx) => {
      return item._id;
    });
    let hprAppsOrderArr = hprAppsOrder.map(item => {
      return item._id;
    });
    let newApps = cloneDeep(apps);
    newApps = newApps.filter(app => {
      return app.checked === true;
    });
    newApps = newApps.map(app => {
      delete app.checked;
      return app;
    });

    this.props.saveClient(
      selectedClient.value,
      newApps,
      reportListType,
      domain,
      authType,
      mdmClientId,
      sDefaultApp,
      defaultAppListItems,
      orderArr,
      hprAppsOrderArr,
      embedType
    );
    this.setState({ unsavedChanges: false });
  }
  async saveMappingHandler() {
    if (this.state.selectedClient !== null) {
      await axios.post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveMapping`,
        {
          client: this.state.selectedClient.value,
          groups: this.state.stateGroups
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      );
      this.props.getGroups();
    }
    this.handleClose();
  }
  handleExcelRefresh() {
    if (this.state.selectedClient !== null) {
      axios
        .post(
          `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/runExcelRefresh`,
          {
            client: this.state.selectedClient.value
            //idToken: localStorage.getItem("id_token")
          },
          {
            headers: {
              //Authorization: "Bearer " + localStorage.getItem("access_token")
              ClientToken: localStorage.getItem("clientToken")
            }
          }
        )
        .then(response => {
          // console.log(response);
          if (response.data.message !== undefined) {
            alert(response.data.message);
          } else {
            alert("Running");
          }
          //dispatch(addClientSuccess(response.data.client));
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
  handleCreate(inputValue) {
    //remove non alphanumeric characters
    inputValue = inputValue.replace(/[\W_]+/g, "");
    this.props.addNewClient(inputValue);
  }
  handleChange = (selectedOption, type = "client") => {
    if (type === "client") {
      this.props.setClient(selectedOption);

      this.props.getAvailableApps(selectedOption.value);
      this.props.getSelectedHPRApps(selectedOption.value);
      this.props.getSelectedDashboardReports(selectedOption.value);
      this.props.getGroups(selectedOption.value);
      this.props.getCustomApps(selectedOption.value);
      this.props.getActiveWorkspaceForClient(selectedOption.value);
      this.setState({
        selectedClient: selectedOption,
        listTypeLoaded: false,
        selectedApp: { label: "Select ...", value: "Select ..." }
      });
    } else if (type === "appGroupPerms") {
      this.props.loadAppPerms(selectedOption);
      this.setState({
        selectedApp: selectedOption
      });
    } else {
      let enableReportUrl = false;
      if (selectedOption.value === "Specified Report") {
        enableReportUrl = true;
      }

      this.props.setDefaultApp(selectedOption);
      this.setState({
        unsavedChanges: true,
        enableReportUrl
      });
    }
  };
  updateInputValue(evt) {
    this.setState({
      //remove non alphanumeric characters
      inputValue: evt.target.value.replace(/[\W_]+/g, "")
    });
  }
  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }
  renderApps() {
    const { apps } = this.state;
    const { loadedApps } = this.props;
    let filteredApps = cloneDeep(apps).filter(item => {
      return item.title !== "Homepage Apps";
    });
    if (loadedApps) {
      return filteredApps.map((app, index) => {
        if (app.title === "Files") {
          return (
            <div key={"parent" + index}>
              <div key={app.id + index}>
                <input
                  type="checkbox"
                  style={{ marginRight: "4px" }}
                  checked={app.checked}
                  //value={app.checked}
                  onChange={event => {
                    this.checkboxChangeHandler(event, app, "app");
                  }}
                />
                {app.title}
              </div>

              <div key={index + "sub"} style={{ marginLeft: "30px" }}>
                <input
                  type="checkbox"
                  checked={app.useFileFolderPermissions}
                  //value={app.checked}
                  onChange={event => {
                    this.checkboxChangeHandler(event, app, "fileChange");
                  }}
                />
                {"Use File Folder Permissions"}
              </div>
            </div>
          );
        } else if (app.type.includes("rpt")) {
          return null;
        }
        return (
          <div key={"CBox" + index}>
            <input
              type="checkbox"
              style={{ marginRight: "4px" }}
              checked={app.checked}
              //value={app.checked}
              onChange={event => {
                this.checkboxChangeHandler(event, app, "app");
              }}
            />
            {app.title}
          </div>
        );
      });
    }
  }

  renderReportListType() {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            type="radio"
            name="listType"
            checked={this.state.reportListType === "list"}
            onChange={event => {
              this.checkboxChangeHandler(event, "list", "reportList");
            }}
          />
          <div style={{ marginLeft: "5px" }}>List</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            type="radio"
            name="listType"
            checked={this.state.reportListType === "iconList"}
            onChange={event => {
              this.checkboxChangeHandler(event, "iconList", "reportList");
            }}
          />
          <div style={{ marginLeft: "5px" }}>Icon List</div>
        </div>
      </div>
    );
  }

  renderAuthType() {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            type="radio"
            name="authType"
            checked={this.state.authType === "azureAd"}
            onChange={event => {
              this.checkboxChangeHandler(event, "azureAd", "authType");
            }}
          />
          <div style={{ marginLeft: "5px" }}>Active Directory</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            type="radio"
            name="authType"
            checked={this.state.authType === "saml"}
            onChange={event => {
              this.checkboxChangeHandler(event, "saml", "authType");
            }}
          />
          <div style={{ marginLeft: "5px" }}>SSO</div>
        </div>
      </div>
    );
  }
  renderEmbedType() {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            type="radio"
            name="embedType"
            checked={this.state.embedType === "pbiMUser"}
            onChange={event => {
              this.checkboxChangeHandler(event, "pbiMUser", "embedType");
            }}
          />
          <div style={{ marginLeft: "5px" }}>PBI Master User</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            type="radio"
            name="embedType"
            checked={this.state.embedType === "servicePrincipal"}
            onChange={event => {
              this.checkboxChangeHandler(
                event,
                "servicePrincipal",
                "embedType"
              );
            }}
          />
          <div style={{ marginLeft: "5px" }}>Service Principal</div>
        </div>
      </div>
    );
  }
  renderDomains() {
    const { domain } = this.state;
    if (domain !== undefined) {
      let domainEl = domain.map((domItem, idx) => {
        return (
          <div
            key={"domain" + idx}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.removeDomainHandler(domItem);
              }}
            >
              x
            </div>
            <div style={{ paddingLeft: "10px" }}>{domItem}</div>
          </div>
        );
      });

      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <input
            onKeyPress={evt => {
              this.inputChangeHandler(evt);
            }}
            //disabled={selectedReport === ""}
            ref={input => (this.domain = input)}
            //value={addDomain}
          />
          {domainEl && domainEl.length > 0 && (
            <div
              style={{
                maxHeight: "100px",
                overflowY: "auto",
                marginTop: "10px",
                border: "1px solid #ccc",
                padding: "4px",
                background: "white"
              }}
            >
              {domainEl}
            </div>
          )}
        </div>
      );
    } else {
      return <div />;
    }
  }
  removeDomainHandler(rmDomain) {
    const { domain } = this.state;
    let newDomains = domain.filter(domItem => {
      return domItem !== rmDomain;
    });
    this.setState({ domain: newDomains, unsavedChanges: true });
  }
  handleClose() {
    this.setState({
      showIsDirtyModal: false,
      showAddClientModal: false,
      showDeleteConfirm: false,
      showGroupMappingModal: false,
      showDashboardReportModal: false,
      showHomepageReportModal: false
    });
    this.props.filterActiveWorkspacesWReports("", this.props.activeWorkspaces);
  }
  deleteHandler() {
    const { hardDelete, removeFromAd } = this.state;

    this.props.deleteClient(
      this.state.selectedClient.value,
      hardDelete,
      removeFromAd
    );
    this.handleClose();
  }
  inputChangeHandler(evt, type) {
    if (type === "mdh") {
      const newValue = this.clientId.value.replace(/[\W_]+/g, "");
      this.setState({
        //remove non alphanumeric characters
        mdmClientId: newValue,
        unsavedChanges: true
      });
    } else if (type === "reportUrl") {
      this.setState({ reportUrl: this.reportUrl });
    }
    if (evt.key === "Enter") {
      if (type === "mdh" || type === "reportUrl") {
        // this.setState({
        //   mdmClientId: this.clientId.value,
        //   unsavedChanges: true
        // });
      } else {
        const { domain } = this.state;
        let newDomain = domain;
        newDomain.push(this.domain.value);
        this.domain.value = "";
        this.setState({
          domain: newDomain,
          unsavedChanges: true
        });
      }
    }
  }
  renderGroups() {
    const { searchGroups, selectedAppPerms } = this.props;

    return searchGroups.map((item, idx) => {
      return (
        <div
          key={item.id + idx}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <input
            type="checkbox"
            style={{ marginTop: "4px" }}
            checked={selectedAppPerms.includes(item.id)}
            onChange={event => {
              this.checkboxChangeHandler(event, item, "permissionSelect");
            }}
          />
          <div style={{ paddingLeft: "2px" }}>{item.name}</div>
        </div>
      );
    });
  }
  renderPermissions(item, index) {
    if (item.permissions !== undefined && item.permissions.length > 0) {
      return item.permissions.map((perm, topIdx) => {
        let retVal = "";
        this.props.userGroups.forEach((ug, idx) => {
          if (perm === ug.id) {
            retVal = (
              <div key={ug.id + "|" + idx + "|" + index + "|" + topIdx}>
                {ug.name}
              </div>
            );
          }
        });
        return retVal;
      });
    } else {
      return (
        <div
          style={{ fontStyle: "italic" }}
          key={"noGroup" + item.id + "|" + index}
        >
          no groups selected
        </div>
      );
    }
  }

  renderAppOverView() {
    const { defaultAppListItems } = this.props;

    return defaultAppListItems.map((item, idx) => {
      return (
        <div
          key={idx + item.name}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div
            key={"div" + item.name + "|" + idx}
            style={{
              padding: "20px 10px",
              width: "60%",
              borderBottom: "1px solid #ccc",
              borderRight: "1px solid #ccc"
            }}
          >
            {item.name}
          </div>
          <div
            key={"otherThing" + idx + "|" + item.name}
            style={{
              padding: "20px 10px",
              width: "40%",
              borderBottom: "1px solid #ccc"
            }}
          >
            {this.renderPermissions(item, idx)}
          </div>
        </div>
      );
    });
  }
  selectGroups(action) {
    const { searchGroups } = this.props;
    let rAction = false;
    if (action === "all") {
      rAction = true;
    }

    searchGroups.forEach(item => {
      this.props.setAppPerms(item, rAction);
    });
    this.setState({ unsavedChanges: true });
  }
  handleInputChange(type) {
    if (type === "groupPerms") {
      this.props.filterGroups(this.groupSearch.value, this.props.userGroups);
    } else if (type === "homepageReportSearch") {
      this.props.filterActiveWorkspaces(
        this.homepageReportSearch.value,
        this.props.activeWorkspaces
      );

      this.props.filterApps(
        this.homepageReportSearch.value,
        this.props.customApps
      );
    } else if (type === "dashboardReports") {
      this.props.filterActiveWorkspacesWReports(
        this.dashboardReportSearch.value,
        this.props.activeWorkspaces
      );
    }
  }
  renderThumbs(thumb, idx, type = "") {
    const { appsOrder, hprAppsOrder, dashboardReportsOrder } = this.state;
    if (type === "dashRpt") {
      if (dashboardReportsOrder.length > 0) {
        let name = thumb.alias;
        if (name === undefined || name === "") {
          name = thumb.name;
        }

        if (name === undefined || name === "") {
          name = thumb.title;
        }

        return (
          <div
            key={thumb.id + "|" + idx}
            style={{
              display: "flex",
              flexDirection: "row",
              // height: "20%",
              overflow: "hidden",
              whiteSpace: "nowrap"
            }}
          >
            {name}
          </div>
        );
      }
    }
    if (type === "hpr") {
      if (hprAppsOrder.length > 0) {
        let name = thumb.alias;
        if (name === undefined || name === "") {
          name = thumb.name;
        }

        if (name === undefined || name === "") {
          name = thumb.title;
        }

        return (
          <div
            key={thumb.id + "|" + idx}
            style={{
              display: "flex",
              flexDirection: "row",
              // height: "20%",
              overflow: "hidden",
              whiteSpace: "nowrap"
            }}
          >
            {name}
          </div>
        );
      }
    } else {
      if (appsOrder.length > 0) {
        let name = thumb.alias;
        if (name === undefined || name === "") {
          name = thumb.name;
        }

        if (name === undefined || name === "") {
          name = thumb.title;
        }

        return (
          <div
            key={thumb.id + "|" + idx}
            style={{
              display: "flex",
              flexDirection: "row",
              // height: "20%",
              overflow: "hidden",
              whiteSpace: "nowrap"
            }}
          >
            {name}
          </div>
        );
      }
    }
  }
  stateGroupChangeHandler(event, result, sType) {
    let nStateGroups = this.state.stateGroups.map(item => {
      if (item.id === result.id) {
        if (sType === "graphId") {
          item.graphId = event.target.value;
        } else {
          item.name = event.target.value;
        }
      }
      return item;
    });
    this.setState({ stateGroups: nStateGroups });
    // console.log(event.currentTarget);
  }
  addDashboardReports() {
    // this.props.filterActiveWorkspacesWReports("", this.props.activeWorkspaces);

    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveDashboardReports`,
        {
          client: this.props.selectedClient.value,
          apps: this.props.selectedDashboardReports
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(async response => {
        // eslint-disable-next-line quotes
        // toast.success('Group "' + value + '" added.');
        // this.props.addUserGroupSuccess(response.data.userGroup);

        await this.props.setSelectedDashboardReports(
          response.data.selected,
          response.data.order
        );
        await this.setState({ listTypeLoaded: false, unsavedChanges: true });
      });
    this.handleClose();
  }
  addHomePageRptApps() {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveHomePageReportApps`,
        {
          client: this.props.selectedClient.value,
          apps: this.props.selectedHPRApps
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(async response => {
        // eslint-disable-next-line quotes
        // toast.success('Group "' + value + '" added.');
        // this.props.addUserGroupSuccess(response.data.userGroup);

        await this.props.setSelectedHPRApps(response.data.selected);
        await this.setState({ listTypeLoaded: false, unsavedChanges: true });
      });
    this.handleClose();
  }
  renderDashboardReports() {
    let filteredWorkspaces = cloneDeep(this.props.searchActiveWorkspaces);
    filteredWorkspaces = filteredWorkspaces.filter(item => {
      return item.type !== "tableau";
    });

    return filteredWorkspaces.map((item, idx) => {
      return (
        <DashboardRptItem
          workspace={item}
          idx={idx}
          checkboxChangeHandler={this.checkboxChangeHandler}
          selectedDashboardReports={this.props.selectedDashboardReports}
        />
      );
    });
  }
  render() {
    const { clients, defaultAppListItems, defaultApp } = this.props;
    const {
      selectedClient,
      showAddClientModal,
      showIsDirtyModal,
      showDeleteConfirm,
      showGroupMappingModal,
      mdmClientId,
      showDashboardReportModal,
      showHomepageReportModal,
      ssoConfigEnabled
    } = this.state;

    return (
      <div style={{ display: "flex", width: "100%" }}>
        <Modal isOpen={showDashboardReportModal}>
          <ModalHeader>Dashboard Reports</ModalHeader>

          <ModalBody>
            <input
              className="input-box"
              placeholder="search"
              ref={input => (this.dashboardReportSearch = input)}
              onChange={() => {
                this.handleInputChange("dashboardReports");
              }}
              style={{
                width: "55%",
                marginTop: "20px",
                marginBottom: "3px"
              }}
            />
            <div style={{ height: "200px", overflowY: "scroll" }}>
              {this.renderDashboardReports()}
            </div>
          </ModalBody>

          <ModalFooter>
            <Button bsstyle="primary" onClick={this.addDashboardReports}>
              Done
            </Button>
            <Button onClick={this.handleClose}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showHomepageReportModal}>
          <ModalHeader>Homepage Reporting Apps</ModalHeader>

          <ModalBody>
            <input
              className="input-box"
              placeholder="search"
              ref={input => (this.homepageReportSearch = input)}
              onChange={() => {
                this.handleInputChange("homepageReportSearch");
              }}
              style={{
                width: "55%",
                marginTop: "20px",
                marginBottom: "3px"
              }}
            />
            <div style={{ height: "200px", overflowY: "scroll" }}>
              {[
                ...this.props.searchCustomApps,
                ...this.props.searchActiveWorkspaces
              ].map((item, idx) => {
                return (
                  <div
                    key={"STUFF" + idx}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <input
                      style={{ marginTop: "4px" }}
                      checked={this.props.selectedHPRApps.includes(item._id)}
                      onChange={e => {
                        // console.log(e);
                        this.checkboxChangeHandler(e, item, "hprApps");
                      }}
                      type="checkbox"
                    />
                    <div
                      style={{ paddingLeft: "2px" }}
                      key={"iItem" + item.id + idx}
                    >
                      {item.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </ModalBody>

          <ModalFooter>
            <Button bsstyle="primary" onClick={this.addHomePageRptApps}>
              Done
            </Button>
            <Button onClick={this.handleClose}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showAddClientModal}>
          <ModalHeader>Add Client</ModalHeader>

          <ModalBody>
            <input
              value={this.state.inputValue}
              onChange={evt => this.updateInputValue(evt)}
            />
          </ModalBody>

          <ModalFooter>
            <Button bsstyle="primary" onClick={this.addClient}>
              Save changes
            </Button>
            <Button onClick={this.handleClose}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showIsDirtyModal}>
          <ModalHeader>Unsaved Changes</ModalHeader>

          <ModalBody>
            You have unsaved changes are you sure you want to discard your
            edits?
          </ModalBody>

          <ModalFooter>
            <Button bsstyle="primary" onClick={this.cancelHandler}>
              Ok
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showGroupMappingModal}>
          <ModalHeader>Azure SSO Group Mapping</ModalHeader>

          <ModalBody>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>Group Id</div>
                <div style={{ marginLeft: "300px" }}>Group Name</div>
              </div>
              <div
                style={{
                  marginTop: "5px",
                  height: "600px",
                  overflowY: "scroll"
                }}
              >
                {this.state.stateGroups.map(result => {
                  return (
                    <div>
                      <input
                        onChange={e => {
                          this.stateGroupChangeHandler(e, result, "graphId");
                        }}
                        style={{ width: "350px" }}
                        value={result.graphId}
                      ></input>
                      <input
                        onChange={e => {
                          this.stateGroupChangeHandler(e, result, "name");
                        }}
                        style={{ width: "300px" }}
                        value={result.name}
                      />
                    </div>
                  );
                })}
              </div>
              <Button
                onClick={() => {
                  // this.props.addBlankGroup();
                  let nStateGroups = this.state.stateGroups;
                  nStateGroups.push({
                    name: "Add Group Name",
                    graphId: "Add Graph Id"
                  });
                  this.setState({ stateGroups: nStateGroups });
                  // this.props.userGroups.push({
                  //   name: "Add Group Name",
                  //   graphId: "Add Graph Id"
                  // });
                }}
                bsstyle="primary"
                style={{ width: "250px" }}
              >
                New Grouping
              </Button>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button bsstyle="primary" onClick={this.saveMappingHandler}>
              Save
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showDeleteConfirm}>
          <ModalHeader>Confirm Deletion</ModalHeader>

          <ModalBody>
            <div>
              Are you sure you want to do remove this client?
              <div
                className="delete-dialog-option"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px"
                }}
              >
                <input
                  type="checkbox"
                  defaultChecked={true}
                  style={{ marginTop: "4px" }}
                  onChange={event => {
                    this.checkboxChangeHandler(
                      event,
                      "removeAD",
                      "clientDelete"
                    );
                  }}
                />
                <div className="delete-dialog-option-text">
                  Remove users from Azure AD
                </div>
              </div>
              <div>
                <div
                  className="delete-dialog-option"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <input
                    type="checkbox"
                    style={{ marginTop: "4px" }}
                    //value={app.checked}
                    onChange={event => {
                      this.checkboxChangeHandler(
                        event,
                        "hardDelete",
                        "clientDelete"
                      );
                    }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="delete-dialog-option-text">
                      Hard delete client (cannot be undone)?
                    </div>
                    <div className="delete-dialog-option-text">
                      (warning, this will remove all client content:
                      users,xls,pbi reports,etc.)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button bsstyle="primary" onClick={this.deleteHandler}>
              Ok
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <div style={{ width: "100%" }}>
          <div
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <div className="page-title">Client Configuration</div>
            <div
              className="client"
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #ccc",
                paddingBottom: "10px"
              }}
            >
              <div className="client-text">Client:</div>
              <Select
                // key={JSON.stringify(clients)} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
                //filterOption={() => true}
                style={{ height: "auto" }}
                className="dropdown"
                value={
                  selectedClient //isClearable
                }
                onChange={item => {
                  this.handleChange(item, "client");
                }}
                onInputChange={this.onInputChange.bind(this)}
                options={clients}
                onCreateOption={
                  this.handleCreate //autoLoad={true}
                }
              />

              <div className="spacer" style={{ width: "10%" }} />
              <div
                className="ta-button ta-button-primary"
                onClick={() => this.setState({ showAddClientModal: true })}
              >
                +Add Client
              </div>
              <div className="action-buttons">
                <div
                  className="ta-button ta-button-primary"
                  style={{
                    marginRight: "20px"
                  }}
                  onClick={() => this.handleExcelRefresh()}
                >
                  Run Excel Refresh
                </div>
                <div
                  className={
                    this.state.unsavedChanges
                      ? "ta-button ta-button-primary"
                      : "ta-button ta-button-disabled"
                  }
                  onClick={() => {
                    if (this.state.unsavedChanges) {
                      this.saveHandler();
                    }
                  }}
                >
                  Save
                </div>

                <div
                  className={
                    this.state.unsavedChanges
                      ? "ta-button ta-button-secondary"
                      : "ta-button ta-button-disabled"
                  }
                  onClick={() =>
                    this.state.unsavedChanges
                      ? this.setState({ showIsDirtyModal: true })
                      : null
                  }
                >
                  Cancel
                </div>
                <div
                  className={"ta-button ta-button-secondary"}
                  onClick={() => this.setState({ showDeleteConfirm: true })}
                >
                  Delete
                </div>
              </div>
            </div>

            <div style={{ overflow: "auto", paddingTop: "10px" }}>
              <div className="settings-row-container-white">
                <div
                  style={{
                    width: "25%",
                    minWidth: "250px"
                  }}
                >
                  <div className="section-heading">Available Applications</div>
                  {this.renderApps()}
                </div>

                <div
                  style={{
                    flexDirection: "column",
                    width: "25%",
                    marginLeft: "40px"
                  }}
                >
                  <div
                    className="section-heading"
                    style={{ marginBottom: "20px" }}
                  >
                    Application Order
                  </div>
                  {this.state.appsOrder.length > 0 && (
                    <div
                      className="drag-order-container"
                      style={{ width: "100%" }}
                    >
                      <DragDropContext
                        onDragEnd={result => {
                          // dropped outside the list
                          if (!result.destination) {
                            return;
                          }

                          const items = reorder(
                            this.state.appsOrder,
                            result.source.index,
                            result.destination.index
                          );

                          this.setState({
                            appsOrder: items,
                            unsavedChanges: true
                          });
                        }}
                      >
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {this.state.appsOrder.map((item, index) => {
                                return (
                                  <Draggable
                                    key={"theTHing" + item._id}
                                    draggableId={item._id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        {this.renderThumbs(item, index, "")}
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "25%",
                    marginLeft: "40px"
                  }}
                >
                  <div
                    className="section-heading"
                    style={{ marginBottom: "10px" }}
                  >
                    Default Application
                  </div>
                  <Select
                    // key={this.state.defaultApps} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
                    //filterOption={() => true}
                    // className="dropdown"
                    value={
                      defaultApp //isClearable
                    }
                    onChange={item => {
                      this.handleChange(item, "app");
                    }}
                    onInputChange={this.onInputChange.bind(this)}
                    options={this.state.defaultApps}
                    onCreateOption={
                      this.handleCreate //autoLoad={true}
                    }
                  />
                  <div
                    style={{
                      marginTop: "20px"
                    }}
                  >
                    <div>Report URL</div>
                    <div style={{ fontSize: "12px" }}>
                      When adding a URL use everything in the URI
                    </div>
                    <div style={{ fontSize: "12px" }}>
                      ex.
                      /powerBI/report/e76f4a07-a1d6-4761-908b-7f6c72ef2562+901b56bb-3ba7-42b3-8a36-de424c3038c4
                    </div>

                    <input
                      style={{ maxWidth: "600px", width: "100%" }}
                      onKeyPress={evt => {
                        this.inputChangeHandler(evt, "reportUrl");
                      }}
                      onChange={e => {
                        this.setState({ unsavedChanges: true });
                        //this.setState();
                      }}
                      //disabled={selectedReport === ""}
                      ref={this.reportUrl}
                      defaultValue={this.state.reportUrl}
                      disabled={!this.state.enableReportUrl}
                    />
                  </div>
                </div>
              </div>

              <div className="settings-row-container-gray" style={{}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "25%"
                  }}
                >
                  <div style={{}}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="section-heading">
                        Homepage Reporting Apps
                      </div>
                      <div className="instruction-text">
                        Select Custom Apps & Workspaces to display on the portal
                        homepage
                      </div>
                      <div
                        style={{
                          width: "fit-content",
                          marginTop: "10px",
                          alignSelf: "center"
                        }}
                        className="ta-button ta-button-primary"
                        onClick={() =>
                          this.setState({ showHomepageReportModal: true })
                        }
                      >
                        Select
                      </div>
                    </div>
                    {/* <div
                    style={{
                      border: "1px solid #ccc",
                      overflow: "auto",
                      maxHeight: "150px",
                      padding: "4px",
                      fontSize: "10px",
                      marginTop: "10px"
                    }}
                  >
                    {this.props.selectedHPRApps.map(item => {
                      // console.log("ITEM");
                      // console.log(item);

                      let retDiv = [];
                      [
                        ...this.props.searchCustomApps,
                        ...this.props.searchActiveWorkspaces
                      ].forEach(sItem => {
                        if (sItem._id === item) {
                          retDiv.push(
                            <div
                              style={{
                                fontSize: "14px",
                                padingTop: "2px",
                                paddingBottom: "2px"
                              }}
                            >
                              {sItem.name}
                            </div>
                          );
                        }
                      });
                      return retDiv;
                    })}
                  </div> */}
                    <div>
                      {this.props.selectedHPRApps.length > 0 ? (
                        <div
                          style={{
                            border: "1px solid #ccc",
                            overflow: "auto",
                            maxHeight: "150px",
                            padding: "4px",
                            fontSize: "10px",
                            marginTop: "10px",
                            background: "white"
                          }}
                        >
                          {this.props.selectedHPRApps.map((item, idx) => {
                            let retDiv = [];
                            [
                              ...this.props.searchCustomApps,
                              ...this.props.searchActiveWorkspaces
                            ].forEach(sItem => {
                              if (sItem._id === item) {
                                retDiv.push(
                                  <div
                                    key={idx}
                                    style={{
                                      fontSize: "14px",
                                      paddingTop: "2px",
                                      paddingBottom: "2px"
                                    }}
                                  >
                                    {sItem.name}
                                  </div>
                                );
                              }
                            });
                            return retDiv;
                          })}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "40px",
                    flexDirection: "column",
                    width: "25%"
                  }}
                >
                  <div className="section-heading">
                    Homepage Reporting Apps Order
                  </div>
                  <div
                    className="instruction-text"
                    style={{ marginBottom: "20px" }}
                  >
                    Adjust the order of Reporting App display
                  </div>
                  {this.state.hprAppsOrder.length > 0 && (
                    <div
                      className="drag-order-container"
                      style={{ width: "100%" }}
                    >
                      <DragDropContext
                        onDragEnd={result => {
                          // dropped outside the list
                          if (!result.destination) {
                            return;
                          }

                          const items = reorder(
                            this.state.hprAppsOrder,
                            result.source.index,
                            result.destination.index
                          );

                          this.setState({
                            hprAppsOrder: items,
                            unsavedChanges: true
                          });
                        }}
                      >
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {this.state.hprAppsOrder.map((item, index) => {
                                return (
                                  <Draggable
                                    key={"theTHing" + item._id}
                                    draggableId={item._id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        {this.renderThumbs(item, index, "hpr")}
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  )}
                </div>
              </div>
              <div className="settings-row-container-gray" style={{}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "25%"
                  }}
                >
                  <div style={{}}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="section-heading">Dashboard Reports</div>
                      <div className="instruction-text">
                        Select Reports to display on the portal homepage
                      </div>
                      <div
                        style={{
                          width: "fit-content",
                          marginTop: "10px",
                          alignSelf: "center"
                        }}
                        className="ta-button ta-button-primary"
                        onClick={() =>
                          this.setState({ showDashboardReportModal: true })
                        }
                      >
                        Select
                      </div>
                    </div>

                    <div>
                      {this.props.selectedDashboardReports.length > 0 ? (
                        <div
                          style={{
                            border: "1px solid #ccc",
                            overflow: "auto",
                            maxHeight: "150px",
                            padding: "4px",
                            fontSize: "10px",
                            marginTop: "10px",
                            background: "white"
                          }}
                        >
                          {this.props.dashboardReportsOrder.map(reportId => {
                            // Find the matching report across all workspaces
                            const matchingReport =
                              this.props.selectedDashboardReports
                                .flatMap(workspace => workspace.reports) // Flatten all reports from workspaces
                                .find(report => report._id === reportId); // Find the matching report by ID

                            // If a matching report is found, render it
                            if (matchingReport) {
                              return (
                                <div
                                  key={matchingReport._id}
                                  style={{
                                    fontSize: "14px",
                                    paddingTop: "2px",
                                    paddingBottom: "2px"
                                  }}
                                >
                                  {matchingReport.name}
                                </div>
                              );
                            } else {
                              return null; // If no matching report, return null (optional)
                            }
                          })}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "40px",
                    flexDirection: "column",
                    width: "25%"
                  }}
                >
                  <div className="section-heading">Dashboard Reports Order</div>
                  <div
                    className="instruction-text"
                    style={{ marginBottom: "20px" }}
                  >
                    Adjust the order of Dashboard Reports
                  </div>
                  {this.state.dashboardReportsOrder.length > 0 && (
                    <div
                      className="drag-order-container"
                      style={{ width: "100%" }}
                    >
                      <DragDropContext
                        onDragEnd={result => {
                          // dropped outside the list
                          if (!result.destination) {
                            return;
                          }

                          const items = reorder(
                            this.state.dashboardReportsOrder,
                            result.source.index,
                            result.destination.index
                          );

                          this.setState({
                            dashboardReportsOrder: items,
                            unsavedChanges: true
                          });
                        }}
                      >
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {this.state.dashboardReportsOrder.map(
                                (item, index) => {
                                  return (
                                    <Draggable
                                      key={"theTHing" + item._id}
                                      draggableId={item._id}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          {this.renderThumbs(
                                            item,
                                            index,
                                            "dashRpt"
                                          )}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="settings-row-container-white"
                style={{ marginTop: "40px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    maxWidth: "100%"
                  }}
                >
                  <div className="section-heading">
                    Application Group Permissions
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "30%"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "0px"
                        }}
                      >
                        <div
                          className="number-circle"
                          style={{ marginBottom: "0px", alignSelf: "center" }}
                        >
                          1
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "10px",
                            marginBottom: "10px"
                          }}
                        >
                          <div className="config-subtitle" style={{}}>
                            Select Application
                          </div>
                          <div className="instruction-text">
                            Select an applicaiton to set group access
                            permissions
                          </div>
                        </div>
                      </div>

                      <div style={{ marginLeft: "-12px", maxWidth: "300px" }}>
                        <Select
                          // key={defaultAppListItems} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
                          //filterOption={() => true}
                          className="dropdown"
                          value={
                            this.state.selectedApp //isClearable
                          }
                          onChange={item => {
                            this.handleChange(item, "appGroupPerms");
                          }}
                          onInputChange={this.onInputChange.bind(this)}
                          options={defaultAppListItems}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "40px"
                        }}
                      >
                        <div
                          className="number-circle"
                          style={{
                            marginBottom: "0px",
                            marginTop: "0px",
                            alignSelf: "center"
                          }}
                        >
                          2
                        </div>
                        <div style={{ flexDirection: "column" }}>
                          <div
                            className="config-subtitle"
                            style={{ alignSelf: "center" }}
                          >
                            Select Groups
                          </div>
                          <div className="instruction-text">
                            Select which groups should have access to the
                            selected application
                          </div>
                        </div>
                      </div>
                      <input
                        className="input-box"
                        placeholder="search"
                        ref={input => (this.groupSearch = input)}
                        onChange={() => {
                          this.handleInputChange("groupPerms");
                        }}
                        style={{
                          width: "55%",
                          // marginTop: "20px",
                          marginBottom: "10px"
                        }}
                      />
                      <div
                        style={{
                          border: "1px solid #ccc",
                          // paddingLeft: "10px",
                          maxHeight: "400px",
                          overflowY: "auto",
                          width: "fit-content",
                          paddingLeft: "8px",
                          paddingRight: "8px"
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{
                              cursor: "pointer",
                              color: "rgba(0, 126, 167, 1)"
                            }}
                            onClick={() => {
                              this.selectGroups("all");
                            }}
                          >
                            select all
                          </div>
                          <div
                            style={{ marginLeft: "2px", marginRight: "2px" }}
                          >
                            /
                          </div>
                          <div
                            style={{
                              cursor: "pointer",
                              color: "rgba(0, 126, 167, 1)"
                            }}
                            onClick={() => {
                              this.selectGroups("none");
                            }}
                          >
                            deselect all
                          </div>
                        </div>
                        {this.renderGroups()}
                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <LeftArrow
                        height={"80px"}
                        color={"rgba(186,186,186,1)"}
                        style={{
                          marginLeft: "20px",
                          transform: "rotate(180deg)"
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "40px",
                        width: "45%"
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          className="number-circle"
                          style={{
                            marginBottom: "0px",
                            marginTop: "0px",
                            alignSelf: "center"
                          }}
                        >
                          3
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignSelf: "center",
                            marginTop: "10px",
                            marginBottom: "10px"
                          }}
                        >
                          <div className="config-subtitle" style={{}}>
                            Review Group Assignments
                          </div>
                          <div className="instruction-text">
                            Review applications and their assigned groups
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            // position: "sticky",
                            top: 0,
                            backgroundColor: "#fff",
                            zIndex: 1
                          }}
                        >
                          <div
                            className="config-subtitle"
                            style={{
                              width: "60%",
                              borderBottom: "1px solid #ccc",
                              paddingBottom: "10px",
                              paddingLeft: "10px",
                              paddingRight: "10px"
                            }}
                          >
                            Application
                          </div>
                          <div
                            className="config-subtitle"
                            style={{
                              width: "40%",
                              borderBottom: "1px solid #ccc",
                              paddingBottom: "10px",
                              paddingLeft: "10px",
                              paddingRight: "10px"
                            }}
                          >
                            Assigned Group(s)
                          </div>
                        </div>
                        <div
                          style={{
                            height: "500px",
                            overflowY: "scroll",
                            border: "1px solid #ccc"
                          }}
                        >
                          {this.renderAppOverView()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "40px"
                }}
              ></div>

              <div className="settings-row-container-gray" style={{}}>
                <div
                  style={{ marginTop: "10px", width: "25%", minWidth: "250px" }}
                >
                  <div>
                    <div className="section-heading">Report List Type</div>
                    {this.renderReportListType()}
                    <div
                      style={{
                        marginLeft: "10px",
                        display: "flex",
                        flexDirection: "row"
                      }}
                    ></div>
                    <div
                      className="section-heading"
                      style={{ marginTop: "40px", marginBottom: "10px" }}
                    >
                      MDH Client ID
                    </div>
                    <input
                      style={{
                        textAlign: "end",
                        width: "40px",
                        border: "1px solid #CCC",
                        borderRadius: "4px"
                      }}
                      onChange={evt => {
                        this.inputChangeHandler(evt, "mdh");
                      }}
                      //disabled={selectedReport === ""}
                      ref={input => (this.clientId = input)}
                      defaultValue={mdmClientId}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginTop: "10px", marginLeft: "40px" }}>
                    <div className="section-heading">Auth Type</div>
                    {this.renderAuthType()}
                  </div>
                  <div
                    style={{ marginLeft: "40px", marginTop: "6px" }}
                    className={`sso-config-container ${
                      ssoConfigEnabled ? "" : "sso-config-disabled"
                    }`}
                  >
                    <div className="config-subtitle">SSO Configuration</div>
                    <div style={{}}>
                      <div style={{}}>
                        <div style={{ fontSize: "13px" }}>Add Domain</div>

                        {this.renderDomains()}
                      </div>
                      <div
                        className="ta-button ta-button-primary"
                        style={{
                          marginTop: "20px"
                        }}
                        onClick={() => {
                          //getGroupMappings
                          this.setState({
                            stateGroups: this.props.userGroups.map(item => {
                              return {
                                name: item.name,
                                id: item.id,
                                graphId: item.graphId
                              };
                            })
                          });
                          this.setState({ showGroupMappingModal: true });
                        }}
                      >
                        Set SSO Group Mappings
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginTop: "10px", marginLeft: "40px" }}>
                    <div className="section-heading">PBI Embedding</div>
                    {this.renderEmbedType()}
                  </div>
                </div>
              </div>
              <div
                className="dashboard-and-homepage-reports"
                style={{ display: "flex", flexDirection: "row" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.clientReducer.clients,
  selectedClient: state.clientReducer.selectedClient,
  availableApps: state.clientReducer.availableApps,
  userGroups: state.groupReducer.userGroups,
  searchGroups: state.groupReducer.searchGroups,
  loadingApps: state.clientReducer.loadingApps,
  loadedApps: state.clientReducer.loadedApps,
  authType: state.clientReducer.authType,
  embedType: state.clientReducer.embedType,
  domain: state.clientReducer.domain,
  mdmClientId: state.clientReducer.mdmClientId,
  reportListType: state.clientReducer.reportListType,
  defaultAppListItems: state.clientReducer.defaultAppListItems,
  defaultApp: state.clientReducer.defaultApp,
  selectedAppPerms: state.clientReducer.selectedAppPerms,
  selectedApp: state.clientReducer.selectedApp,
  customApps: state.customAppReducer.customApps,
  searchCustomApps: state.customAppReducer.searchCustomApps,
  searchActiveWorkspaces: state.workspaceReducer.searchActiveWorkspaces,
  activeWorkspaces: state.workspaceReducer.activeWorkspaces,
  activeLoading: state.workspaceReducer.activeLoading,
  selectedHPRApps: state.clientReducer.selectedHPRApps,
  runUpdateHPRApps: state.clientReducer.runUpdateHPRApps,
  selectedDashboardReports: state.clientReducer.selectedDashboardReports,
  dashboardReportsOrder: state.clientReducer.dashboardReportsOrder
});
const mapDispatchToProps = dispatch => ({
  getClients() {
    dispatch(getClients());
  },
  setClient(client) {
    dispatch(setClient(client));
  },
  addNewClient(client) {
    dispatch(addNewClient(client));
  },
  getAvailableApps(client) {
    dispatch(getAvailableApps(client));
  },
  saveClient(
    client,
    apps,
    reportListType,
    domain,
    authType,
    mdmClientId,
    defaultApp,
    appsPerms,
    appsOrder,
    hprAppsOrder,
    embedType
  ) {
    dispatch(
      saveClient(
        client,
        apps,
        reportListType,
        domain,
        authType,
        mdmClientId,
        defaultApp,
        appsPerms,
        appsOrder,
        hprAppsOrder,
        embedType
      )
    );
  },
  getGroups(client) {
    dispatch(getGroups(client));
  },
  deleteClient(client, hardDelete, removeFromAd) {
    dispatch(deleteClient(client, hardDelete, removeFromAd));
  },
  runExcelRefresh(client) {
    dispatch(runExcelRefresh(client));
  },
  setDefaultApp(defaultApp) {
    dispatch(setDefaultApp(defaultApp));
  },
  loadAppPerms(app) {
    dispatch(loadAppPerms(app));
  },
  setAppPerms(perms, selectAll) {
    dispatch(setAppPerms(perms, selectAll));
  },
  setDefaultAppList(list) {
    dispatch(setDefaultAppList(list));
  },
  filterGroups(filter, groups) {
    dispatch(filterGroups(filter, groups));
  },
  getCustomApps(client) {
    dispatch(getCustomApps(client));
  },
  getActiveWorkspaceForClient(client) {
    dispatch(getActiveWorkspaceForClient(client));
  },
  addBlankGroup() {
    dispatch(addBlankGroup());
  },
  filterApps(filter, apps) {
    dispatch(filterApps(filter, apps));
  },
  filterActiveWorkspaces(filter, workspaces) {
    dispatch(filterActiveWorkspaces(filter, workspaces));
  },
  filterActiveWorkspacesWReports(filter, workspaces) {
    dispatch(filterActiveWorkspacesWReports(filter, workspaces));
  },
  getSelectedHPRApps(client) {
    dispatch(getSelectedHPRApps(client));
  },
  getSelectedDashboardReports(client) {
    dispatch(getSelectedDashboardReports(client));
  },
  setSelectedHPRApps(app) {
    dispatch(setSelectedHPRApps(app));
  },
  setHPRUpdateStatus() {
    dispatch(setHPRUpdateStatus());
  },
  setSelectedDashboardReports(app, order) {
    dispatch(setSelectedDashboardReports(app, order));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Client);
